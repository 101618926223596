/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-plusplus */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-array-index-key */

/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import axios from "axios";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import { useTranslation } from "react-i18next";
// import DeleteLab from "./delete_lab";
import Checkbox from "@mui/material/Checkbox";
import "./loading.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useOrders from "hooks/orders/useOrders";
import DeleteDialog from "components/deleteDialog";
import useOrdersArchive from "hooks/orders/useOrdersArchive";

function AllOrdersArchive() {
  const {
    loading,
    containers,
    customers1,
    dialogOpen,
    setDialogOpen,
    handleChangeContainer,
    handleChangeCustomer,
    t,
    navigate,
    filteredOrder,
    dialogdelete,
    openDialog,
    closeDialog,
    executeDelete,
    duplicateModal,
    setDuplicateModal,
    duplicateOrder,
    duplicateId,
    setDuplicateId,
    loadingDuplicate,
    setLoadingDuplicate,
    dialogSuccess,
    setDialogError,
    setDialogSuccess,
    dialogError,
  } = useOrdersArchive();
  return (
    <>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Orders</SuiTypography>
  </SuiBox> */}
            <div
              onClick={() => setDialogOpen(true)}
              style={{
                color: "#17c1e8",
                cursor: "pointer",
                marginLeft: "20px",
                marginTop: "10px",
                width: "10px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-arrow-left-square"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                />
              </svg>
            </div>
            <div
              style={{
                width: "1000px",
                margin: "20px",
                color: "green",
              }}
            >
              <div className="row">
                <div className="form-group  col-md-3">
                  <label
                    className="control-label"
                    htmlFor="name"
                    style={{ fontSize: "15px", marginTop: "20px", color: "black" }}
                  >
                    Filter Containers
                  </label>
                  <select
                    id="shop"
                    className="form-control"
                    style={{ height: "40px" }}
                    onChange={(e) => handleChangeContainer(e)}
                  >
                    <option value="all">All</option>
                    {containers.map((user) => (
                      <option value={user.id}>{user.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group  col-md-3">
                  <label
                    className="control-label"
                    htmlFor="name"
                    style={{ fontSize: "15px", marginTop: "20px", color: "black" }}
                  >
                    Filter Customers
                  </label>
                  <select
                    id="shop"
                    className="form-control"
                    style={{ height: "40px" }}
                    onChange={(e) => handleChangeCustomer(e)}
                  >
                    <option value="all">All</option>
                    {customers1.map((user1, index) => (
                      <option value={user1.code} key={index}>
                        {user1.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {loading ? (
              <div
                style={{
                  width: "1000",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <progress className="pure-material-progress-circular" />
              </div>
            ) : (
              <Table sx={{ minWidth: 1300, overflow: "scroll" }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                        O. Code
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        D. Date
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        P. No.
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                        Customer
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                        Container
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                        Status
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                        T/Amount
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                        T/CTN
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                        T/G.W
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                        T/CBM
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 11 }} align="start"></TableCell>
                    </Box>
                  </TableRow>
                </TableHead>
                {filteredOrder && filteredOrder.length > 0 ? (
                  <TableBody>
                    {filteredOrder.map((user) => (
                      <TableRow
                        key={user.id}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },
                          display: "flex",
                        }}
                      >
                        <Box style={{ overflowX: "auto" }}>
                          <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                            {user.order_code ?? "-"}
                          </TableCell>

                          <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                            {user.delivery_date ?? "-"}
                          </TableCell>

                          <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                            {user.phone_number ?? ""}
                          </TableCell>

                          <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                            {user.customer_name ?? ""}
                          </TableCell>

                          <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                            {user.container_name ?? ""}
                          </TableCell>

                          <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                            {user.status ?? ""}
                          </TableCell>

                          <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                            {Number(user.total_amount).toFixed(2) ?? ""}
                          </TableCell>

                          <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                            {Number(user.total_ctn).toFixed(2) ?? ""}
                          </TableCell>

                          <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                            {Number(user.total_gw).toFixed(2) ?? ""}
                          </TableCell>

                          <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                            {Number(user.total_t_cbm).toFixed(2) ?? ""}
                          </TableCell>

                          <TableCell sx={{ width: window.innerWidth / 11 }} align="start">
                            <SuiTypography
                              component="a"
                              href="#"
                              variant="caption"
                              color="success"
                              fontWeight="medium"
                              margin="15px"
                              onClick={() => {
                                setDuplicateId(user.id);
                                setDuplicateModal(true);
                              }}
                            >
                              Duplicate
                            </SuiTypography>
                            <SuiTypography
                              component="a"
                              variant="caption"
                              color="primary"
                              fontWeight="medium"
                              margin="15px"
                              onClick={() => {
                                openDialog(user.id);
                              }}
                            >
                              {t("delete")}
                            </SuiTypography>{" "}
                          </TableCell>
                        </Box>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 2 }} align="center">
                        There is no data to display
                      </TableCell>
                    </Box>
                  </TableBody>
                )}
              </Table>
            )}

            {/* </TableContainer> */}
            <DeleteDialog
              dialogdelete={dialogdelete}
              closeDialog={closeDialog}
              deleteItem={executeDelete}
            />
          </Card>
        </SuiBox>
      </SuiBox>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to go back? </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
Let Google help apps determine location. This means sending anonymous location data to
Google, even when no apps are running.
</DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate("/containers");
            }}
            autoFocus
          >
            YES
          </Button>
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={duplicateModal}
        onClose={() => setDuplicateModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ margin: "20px" }}>
          {t("Are you sure you want to duplicate this order?")}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            style={{ color: "white", margin: "10px" }}
            onClick={() => {
              duplicateOrder();
              setDuplicateModal(false);
            }}
            autoFocus
          >
            {t("yes")}
          </Button>
          <Button
            onClick={() => {
              setDuplicateModal(false);
            }}
            autoFocus
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogSuccess}
        onClose={() => {
          setDialogSuccess(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Order Duplicated Successfully</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogSuccess(false);
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogError}
        onClose={() => {
          setDialogError(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Duplicating order failed</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogError(false);
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </>
  );
}

export default AllOrdersArchive;
