import { useState } from "react";
import axios from "axios";

const useDelete = (path, refreshFunc) => {
  const [dialogdelete, setDialogDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);

  const openDialog = (id) => {
    console.log(path);
    setDeleteId(id);
    setDialogDelete(true);
    console.log(id, deleteId);
  };
  const closeDialog = () => {
    setDialogDelete(false);
    setDeleteId(null);
  };

  const executeDelete = () => {
    console.log(deleteId);
    const token = sessionStorage.getItem("token");
    console.log("token");
    console.log(token);
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };

    axios
      .post(`https://trust-cargo.co/anas_backend/api/${path}`, { id: deleteId }, { headers })
      .then((response) => {
        console.log(response);
        refreshFunc();
        closeDialog();
        setOpenDeleteSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
    openDeleteSuccess,
    setOpenDeleteSuccess,
  };
};

export default useDelete;
