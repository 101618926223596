/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from "react";
import axios from "axios";

const useContainerEditing = (containerId) => {
  const [fromName, setName] = useState([]);
  const [formNumber, setNumber] = useState([]);
  const [containerStatus, setStatus] = useState([]);
  const [containerNotes, setNotes] = useState([]);
  const [loadingDate, setLoadingDate] = useState([]);
  const [estimatedArrivalDate, setEstimatedArrivalDate] = useState([]);
  const [telexStatus, setTelexStatus] = useState([]);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/specefic_container_data/${containerId}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setName(data["container"]["name"]);
    setNumber(data["container"]["number"]);
    setStatus(data["container"]["status"]);
    setNotes(data["container"]["notes"]);
    setLoadingDate(data["container"]["loading_date"]);
    setEstimatedArrivalDate(data["container"]["estimated_arrival_date"]);
    setTelexStatus(data["container"]["telex_status"]);
    console.log(data["container"]["telex_status"]);
  };
  useEffect(() => {
    fetchData();
  }, []);

  function editContainer() {
    // const token = sessionStorage.getItem("token");
    console.log(telexStatus);
    setLoading(true);
    const headers = { ContentType: `application/json` };
    axios
      .post(
        "https://trust-cargo.co/anas_backend/api/edit_container",
        {
          id: containerId,
          name: fromName,
          number: formNumber,
          status: containerStatus,
          notes: containerNotes,
          loading_date: loadingDate,
          estimated_arrival_date: estimatedArrivalDate,
          telex_status: telexStatus,
        },
        { headers }
      )
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }
  return {
    name: fromName,
    setName,
    number: formNumber,
    setNumber,
    status: containerStatus,
    setStatus,
    notes: containerNotes,
    setNotes,
    loadingDate,
    setLoadingDate,
    estimatedArrivalDate,
    setEstimatedArrivalDate,
    telexStatus,
    setTelexStatus,
    dialogError,
    setDialogError,
    dialogSuccess,
    setDialogSuccess,
    editContainer,
    loading,
  };
};

export default useContainerEditing;
