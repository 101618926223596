import useDelete from "hooks/general/useDelete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useOrdersArchive = () => {
  const [loading, setLoading] = useState();
  const [orders, setOrders] = useState([]);
  const [containers, setContainers] = useState([]);
  const [customers1, setCustomers1] = useState([]);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [ordersWithStatus, setOrdersWithStatus] = useState([]);
  const [duplicateId, setDuplicateId] = useState([]);
  const [loadingDuplicate, setLoadingDuplicate] = useState();
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const { t } = useTranslation();
  // const { t } = useTranslation();
  const getAllOrder = async () => {
    try {
      // const token = sessionStorage.getItem("token");
      const headers = { ContentType: "application/json", Accept: "application/json" };
      const response = await fetch(`https://trust-cargo.co/anas_backend/api/all_orders`, {
        headers,
      });
      const data = await response.json();

      // Create an array of objects containing order IDs and statuses
      const ordersWithStatus1 = data.orders.map((order) => ({
        id: order.id,
        status: order.status,
      }));
      setOrdersWithStatus(ordersWithStatus1);
      // Set orders and checked state
      setOrders(data.orders);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
      // Handle error if needed
    }
  };

  const getContainers = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/containers");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setContainers(data["containers"]);
  };
  const getCustomers = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/customers");
    const data = await response.json();
    setCustomers1(data.customers);
  };

  useEffect(() => {
    setLoading(true);
    getAllOrder();
    getCustomers();
    getContainers();
  }, []);

  const filteredOrder = orders.filter((order) => {
    if (
      (!selectedContainer || order.container_id === selectedContainer) &&
      (!selectedCustomer || order.customer_id === selectedCustomer)
    ) {
      return true;
    }
    if (selectedContainer === "all" || selectedCustomer === "all") {
      return true;
    }
    return false;
  });
  const handleChangeContainer = (event) => {
    const selectedValue = event.target.value;
    setSelectedContainer(selectedValue);
    const ordersWithStatus1 = filteredOrder.map((order) => ({
      id: order.id,
      status: order.status,
    }));
    setOrdersWithStatus(ordersWithStatus1);
  };
  const handleChangeCustomer = (event) => {
    const selectedValue = event.target.value;
    setSelectedCustomer(selectedValue);
    const ordersWithStatus1 = filteredOrder.map((order) => ({
      id: order.id,
      status: order.status,
    }));
    setOrdersWithStatus(ordersWithStatus1);
  };
  console.log("ordersWithStatus");
  console.log(ordersWithStatus);

  const duplicateOrder = async () => {
    setLoadingDuplicate(true);
    // const token = sessionStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    axios
      .post(`https://trust-cargo.co/anas_backend/api/duplicate-order/${duplicateId}`, { headers })
      .then((response) => {
        console.log(response);
        setLoadingDuplicate(false);
        setDialogSuccess(true);
        setLoading(true);

        getAllOrder();
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  };

  const navigate = useNavigate();

  const { dialogdelete, setDialogDelete, openDialog, closeDialog, executeDelete } = useDelete(
    "delete_order",
    getAllOrder
  );

  return {
    loading,
    containers,
    customers1,
    dialogOpen,
    setDialogOpen,
    handleChangeContainer,
    handleChangeCustomer,
    setDialogDelete,
    t,
    navigate,
    filteredOrder,
    dialogdelete,
    openDialog,
    closeDialog,
    executeDelete,
    duplicateModal,
    setDuplicateModal,
    duplicateOrder,
    duplicateId,
    setDuplicateId,
    loadingDuplicate,
    setLoadingDuplicate,
    dialogSuccess,
    setDialogError,
    setDialogSuccess,
    dialogError,
  };
};

export default useOrdersArchive;
