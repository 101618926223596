/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-else-return */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/order */
/* eslint-disable prefer-const */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-param-reassign: "error" */
/* eslint no-underscore-dangle: 0 */

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import React from "react";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import {
  AppBar,
  Toolbar,
  TableContainer,
  // Paper,
} from "@mui/material";
import close from "assets/images/close.png";
import { useNavigate } from "react-router-dom";
import "../../styles/style.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@material-ui/core";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import image from "../../assets/images/image.png";
import Excel from "exceljs";
import { useTranslation } from "react-i18next";
import useAllOrdersDetails from "hooks/orders/useAllOrdersDetails";

const columns = [
  {
    header: "order code",
    key: "order_code",
  },
  {
    header: "our item",
    key: "our_item",
  },
  {
    header: "description",
    key: "description2",
  },
  {
    header: "photo",
    key: "photo",
  },
  {
    header: "CTN",
    key: "ctn",
  },
  {
    header: "QTY/CTN",
    key: "qty_ctn",
  },
  {
    header: "T/QTY",
    key: "t_qty",
  },
  {
    header: "UPrice",
    key: "u_price",
  },
  {
    header: "CBM",
    key: "cbm",
  },
  {
    header: "T/CBM",
    key: "t_cbm",
  },
  {
    header: "N.W",
    key: "n_w",
  },
  {
    header: "G.W",
    key: "g_w",
  },
];

const workSheetName = "trust-cargo";
const workBookName = "trustworkbook";

const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
  return promise;
};

function AllOrders() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    validated,
    handleSubmit,
    handleChangeContainerID,
    filteredOrders,
    handleFieldChange,
    markAll,
    toggleMarkedItem,
    dialogOpen,
    setDialogOpen,
    loading,
    selectedOrders,
    markedItems,
    customers,
    containers,
    totalCTN,
    totalGW,
    totalTCBM,
    totalAmount,
    statusFilter,
    setStatusFilter,
    customerFilter,
    setCustomerFilter,
    containerFilter,
    setContainerFilter,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
    setDialogError,
    dialogNew,
    setDialogNew,
  } = useAllOrdersDetails();
  const cellStyle = {
    minWidth: 200,
    padding: ".5em 1em",
    textAlign: "left",
    borderLeft: "1px solid white",
    borderBottom: "1px solid white",
  };

  const saveExcel = async () => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet("Sheet");
    sheet.properties.defaultRowHeight = 80;
    sheet.columns = [
      { header: "Order Code", key: "order_code", width: 10 },
      { header: "Our Item", key: "our_item", width: 10 },
      { header: "Description English", key: "description2", width: 10 },
      { header: "photo", key: "photo", width: 20 },
      { header: "ctn", key: "ctn", width: 10 },
      { header: "qty_ctn", key: "qty_ctn", width: 10 },
      { header: "t_qty", key: "t_qty", width: 10 },
      { header: "u_price", key: "u_price", width: 10 },
      { header: "cbm", key: "cbm", width: 10 },
      { header: "t_cbm", key: "t_cbm", width: 10 },
      { header: "n_w", key: "n_w", width: 10 },
      { header: "g_w", key: "g_w", width: 10 },
    ];
    const promise = Promise.all(
      selectedOrders?.map(async (product, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          order_code: product.order_code,
          our_item: product.our_item,
          description2: product.description2,
          ctn: Number(product.ctn).toFixed(2),
          qty_ctn: Number(product.qty_ctn).toFixed(2),
          t_qty: Number(product.t_qty).toFixed(2),
          u_price: Number(product.u_price).toFixed(2),
          cbm: Number(product.cbm).toFixed(2),
          t_cbm: Number(product.t_cbm).toFixed(2),
          n_w: Number(product.n_w).toFixed(2),
          g_w: Number(product.g_w).toFixed(2),
        });
        const result = await toDataURL(product?.photo);
        const splitted = product?.photo.split(".");
        const extName = splitted[splitted.length - 1];
        let imageID2;
        if (product?.photo === "https://trust-cargo.co/anas_backend/storage/image/") {
          imageID2 = workbook.addImage({
            base64: close,
            extension: "jpg",
          });
        } else {
          imageID2 = workbook.addImage({
            base64: result.base64Url,
            extension: extName,
          });
        }
        // const imageId2 = workbook.addImage({
        //   base64: result.base64Url,
        //   extension: extName,
        // });

        sheet.addImage(imageID2, {
          tl: { col: 3, row: rowNumber },
          ext: { width: 100, height: 100 },
        });
      })
    );

    promise.then(() => {
      const col5 = sheet.getColumn(5);
      col5.eachCell((cell, row) => {
        if (row > 1) {
          cell.value = +cell.value;
        }
      });
      const col6 = sheet.getColumn(6);
      col6.eachCell((cell, row) => {
        if (row > 1) {
          cell.value = +cell.value;
        }
      });
      const col7 = sheet.getColumn(7);
      col7.eachCell((cell, row) => {
        if (row > 1) {
          cell.value = +cell.value;
        }
      });
      const col8 = sheet.getColumn(8);
      col8.eachCell((cell, row) => {
        if (row > 1) {
          cell.value = +cell.value;
        }
      });
      const col9 = sheet.getColumn(9);
      col9.eachCell((cell, row) => {
        if (row > 1) {
          cell.value = +cell.value;
        }
      });
      const col10 = sheet.getColumn(10);
      col10.eachCell((cell, row) => {
        if (row > 1) {
          cell.value = +cell.value;
        }
      });
      const col11 = sheet.getColumn(11);
      col11.eachCell((cell, row) => {
        if (row > 1) {
          cell.value = +cell.value;
        }
      });
      const col12 = sheet.getColumn(12);
      col12.eachCell((cell, row) => {
        if (row > 1) {
          cell.value = +cell.value;
        }
      });
      // iterate over all current cells in this column

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "sheet.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <SuiBox py={3}>
      <div style={{ zIndex: "3" }}>
        <AppBar position="fixed" style={{ backgroundColor: "white" }} className="shadow">
          <Toolbar style={{ paddingLeft: "0px" }}>
            <div className="row" style={{ width: "2000px", margin: "", color: "", display: "" }}>
              <div
                className="d-flex"
                style={{ marginTop: "0.5rem", width: "100%", marginLeft: "1rem" }}
              >
                <div
                  onClick={() => setDialogOpen(true)}
                  style={{
                    color: "#17c1e8",
                    cursor: "pointer",
                    width: "10px",
                    marginRight: "3rem",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-arrow-left-square"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                    />
                  </svg>
                </div>
                <h2 style={{ marginLeft: "0.5rem", marginRight: "6rem" }}>All Orders Details</h2>
                <div className="column" style={{ display: "flex" }}>
                  <div className="col-md-3 me-1">
                    <div className="form-group">
                      <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                        Total CTN
                      </label>
                      <input
                        required
                        id="shop_no2"
                        type="text"
                        className="form-control"
                        name="name"
                        readOnly
                        placeholder="Total CTN"
                        style={{ width: "150px", minWidth: "150px" }}
                        value={totalCTN}
                      />
                    </div>
                  </div>
                  <div className="col-md-3  me-1">
                    <div className="form-group">
                      <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                        Total G.w
                      </label>
                      <input
                        required
                        id="shop_no2"
                        type="text"
                        className="form-control"
                        name="name"
                        readOnly
                        value={totalGW}
                        style={{ width: "150px", minWidth: "150px" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3  me-1">
                    <div className="form-group">
                      <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                        To. T_CBM
                      </label>
                      <input
                        required
                        id="shop_no2"
                        type="text"
                        className="form-control"
                        name="name"
                        readOnly
                        value={totalTCBM}
                        style={{ width: "150px", minWidth: "150px" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3  me-1">
                    <div className="form-group">
                      <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                        Total Amount
                      </label>
                      <input
                        required
                        id="shop_no2"
                        type="text"
                        className="form-control"
                        name="name"
                        readOnly
                        value={totalAmount}
                        style={{ width: "150px", minWidth: "150px" }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    marginLeft: "10px",
                    marginTop: "30px",
                    backgroundColor: "white",
                    display: "",
                    width: "30%",
                  }}
                >
                  <div
                    className="form-group  col-md-4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <CFormSelect
                      id="containerId"
                      onChange={(e) => setContainerFilter(e.target.value)}
                      value={containerFilter}
                    >
                      <option value="all">Filter containers</option>
                      {containers.map((user) => (
                        <option value={user.id}>{user.name}</option>
                      ))}
                    </CFormSelect>
                  </div>
                  <div
                    className="form-group  col-md-4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <CFormSelect
                      id="containerId"
                      onChange={(e) => setCustomerFilter(e.target.value)}
                      value={customerFilter}
                    >
                      <option value="all">Filter customers</option>
                      {customers.map((user1) => (
                        <option value={user1.code} key={user1.id}>
                          {user1.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                  <div
                    className="form-group  col-md-4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <CFormSelect
                      id="containerId"
                      onChange={(e) => setStatusFilter(e.target.value)}
                      value={statusFilter}
                    >
                      <option value="all">Filter status</option>
                      <option value="new">New</option>
                      <option value="received">Received</option>
                    </CFormSelect>
                  </div>{" "}
                </div>
              </div>
              <div style={{ display: "flex", marginLeft: "1rem" }}>
                <CForm
                  className="row g-3 needs-validation"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  <CRow>
                    <CCol
                      className="form-group"
                      style={{ marginTop: "2rem", marginBottom: "0.5rem" }}
                    >
                      <CFormSelect
                        id="containerId"
                        required
                        feedbackInvalid="container ID is required"
                        onChange={(e) => handleChangeContainerID(e.target.value)}
                      >
                        <option value="" selected="">
                          Select a container
                        </option>
                        {containers.map((user) => (
                          <option value={user.id}>{user.name}</option>
                        ))}
                      </CFormSelect>
                    </CCol>
                    <CCol
                      className="form-group"
                      style={{
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        marginRight: "0.5rem",
                        marginLeft: "0.5rem",
                        color: "white",
                      }}
                    >
                      <div
                        className="form-group"
                        style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
                      >
                        <Button variant="contained" style={{ color: "white" }} type="submit">
                          Confirm Order
                        </Button>
                      </div>
                    </CCol>{" "}
                  </CRow>
                </CForm>
                <div className="form-group" style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
                  <Button variant="contained" style={{ color: "white" }} onClick={saveExcel}>
                    Export to Excel
                  </Button>
                </div>
              </div>
            </div>{" "}
          </Toolbar>
        </AppBar>{" "}
      </div>
      <Card style={{ paddingTop: "5rem", paddingBottom: "5rem" }}>
        {loading ? (
          <div
            style={{
              width: "1000",
              height: "50px",
              display: "flex",
              justifyContent: "start",
              marginTop: "100px",
            }}
          >
            <progress className="pure-material-progress-circular" />
          </div>
        ) : (
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <TableContainer style={{ marginTop: "6rem", height: "73vh" }}>
              {filteredOrders && filteredOrders.length > 0 ? (
                <ScrollSync>
                  <div style={{ display: "flex", position: "relative", height: 300 }}>
                    <CTable bordered>
                      <ScrollSyncPane group="horizontal">
                        <CTableHead
                          className="header"
                          color="light"
                          style={{
                            position: "fixed",
                            top: 154,
                            overflow: "auto",
                            display: "block",
                            width: "100vw",
                          }}
                        >
                          <CTableRow style={{ fontSize: "18px", position: "sticky" }}>
                            <CTableHeaderCell
                              className="text-center"
                              style={{
                                width: 50,
                                minWidth: 50,
                                paddingLeft: 0,
                                paddingRight: 0,
                                borderRight: "none",
                                borderLeft: "none",
                              }}
                            >
                              <CButton
                                style={{
                                  border: "none",
                                }}
                                onClick={markAll}
                              >
                                All
                              </CButton>
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: 118, minWidth: 118 }}
                            >
                              O.Code
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: 118, minWidth: 118 }}
                            >
                              Container
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "116px", minWidth: "116px" }}
                            >
                              Our Item
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "117px", minWidth: "117px" }}
                            >
                              Item No.
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "118px", minWidth: "118px" }}
                            >
                              Image
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "218px", minWidth: "218px" }}
                            >
                              Desc En.
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "80px", minWidth: "80px" }}
                            >
                              {" "}
                              CTN
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "80px", minWidth: "80px" }}
                            >
                              {" "}
                              QTY/CTN
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "80px", minWidth: "80px" }}
                            >
                              T/QTY
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "80px", minWidth: "80px" }}
                            >
                              UPrice
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "80px", minWidth: "80px" }}
                            >
                              T_UPrice
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "80px", minWidth: "80px" }}
                            >
                              CBM
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "80px", minWidth: "80px" }}
                            >
                              T/CBM
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "80px", minWidth: "80px" }}
                            >
                              N.W
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "80px", minWidth: "80px" }}
                            >
                              G.W
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "117px", minWidth: "117px" }}
                            >
                              Status
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "117px", minWidth: "117px" }}
                            >
                              O.Status
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              className="text-center"
                              style={{ width: "117px", minWidth: "117px" }}
                            >
                              Notes
                            </CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>
                      </ScrollSyncPane>
                      <ScrollSyncPane group="horizontal">
                        <CTableBody style={{ display: "block", overflow: "auto", width: "100vw" }}>
                          {filteredOrders.map((item, index) => (
                            <CTableRow
                              v-for="item in tableItems"
                              key={index}
                              style={
                                markedItems.includes(item.id)
                                  ? { minWidth: "100px" }
                                  : { backgroundColor: "#c8c8c8", minWidth: "100px" }
                              }
                            >
                              <CTableDataCell
                                className="text-center fw-medium"
                                style={{ width: 50, minWidth: 50 }}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <CFormCheck
                                    id="id"
                                    className="customCheckbox"
                                    label=" "
                                    reverse
                                    checked={markedItems.find((id) => id === item.id)}
                                    onChange={() => {
                                      toggleMarkedItem(item.id);
                                    }}
                                  />
                                </div>
                              </CTableDataCell>
                              <CTableDataCell
                                className="text-center"
                                style={{ width: 110, minWidth: 110 }}
                              >
                                <input
                                  id="orderCode"
                                  required
                                  onChange={(e) =>
                                    handleFieldChange(index, "order_code", e.target.value)
                                  }
                                  value={item.order_code}
                                  type="text"
                                  style={{ width: "100px", minWidth: "100px" }}
                                  className="form-control"
                                  name="name"
                                  disabled={markedItems.includes(item.id) === false}
                                  placeholder="Order Code"
                                />
                              </CTableDataCell>
                              <CTableDataCell
                                className="text-center"
                                style={{ width: "110px", minWidth: "110px" }}
                              >
                                <input
                                  id="containerName"
                                  required
                                  disabled={markedItems.includes(item.id) === false}
                                  value={item.container_name}
                                  readOnly
                                  type="text"
                                  style={{ width: "100px", minWidth: "100px" }}
                                  className="form-control"
                                  name="name"
                                  placeholder="Container Name"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  id="ourItem"
                                  required
                                  disabled={markedItems.includes(item.id) === false}
                                  onChange={(e) =>
                                    handleFieldChange(index, "our_item", e.target.value)
                                  }
                                  value={item.our_item}
                                  type="text"
                                  style={{ width: "100px", minWidth: "90px" }}
                                  className="form-control"
                                  name="name"
                                  placeholder="Our Item"
                                />
                              </CTableDataCell>{" "}
                              <CTableDataCell className="text-center">
                                <input
                                  id="itemNumber"
                                  required
                                  disabled={markedItems.includes(item.id) === false}
                                  onChange={(e) =>
                                    handleFieldChange(index, "item_number", e.target.value)
                                  }
                                  value={item.item_number}
                                  type="text"
                                  style={{ width: "100px", minWidth: "90px" }}
                                  className="form-control"
                                  name="name"
                                  placeholder="Item Number"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                {item.photo === "https://trust-cargo.co/anas_backend/storage/" ? (
                                  <img src={image} style={{ width: "100px" }} alt="Default Image" />
                                ) : (
                                  <img
                                    src={item.photo}
                                    style={{ width: "100px", height: "63px" }}
                                    alt=""
                                  />
                                )}
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  id="desc2"
                                  type="text"
                                  style={{ width: "200px", minWidth: "200px" }}
                                  className="form-control"
                                  name="name"
                                  disabled={markedItems.includes(item.id) === false}
                                  placeholder="Desc English"
                                  onChange={(e) =>
                                    handleFieldChange(index, "description2", e.target.value)
                                  }
                                  value={item.description2}
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  type="text"
                                  style={{ width: "63px", minWidth: "63px" }}
                                  className="form-control"
                                  name="name"
                                  placeholder="CTN"
                                  disabled={markedItems.includes(item.id) === false}
                                  onChange={(e) => handleFieldChange(index, "ctn", e.target.value)}
                                  value={item.ctn}
                                  id="ctn"
                                />
                              </CTableDataCell>
                              <CTableDataCell
                                className="text-center"
                                style={{ width: "96px", minWidth: "96px" }}
                              >
                                <input
                                  required
                                  type="text"
                                  style={{ width: "66px", minWidth: "66px" }}
                                  className="form-control"
                                  name="name"
                                  disabled={markedItems.includes(item.id) === false}
                                  placeholder="QTY/CTN"
                                  onChange={(e) =>
                                    handleFieldChange(index, "qty_ctn", e.target.value)
                                  }
                                  value={item.qty_ctn}
                                  id="qtytoctn"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  type="text"
                                  style={{ width: "63px", minWidth: "63px" }}
                                  className="form-control"
                                  name="name"
                                  readOnly
                                  placeholder="T/QTY"
                                  value={
                                    item.ctn === "0" || item.ctn === ""
                                      ? item.qty_ctn
                                      : item.ctn * item.qty_ctn
                                  }
                                  id="ttoqty"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  id="uprice"
                                  type="text"
                                  style={{ width: "63px", minWidth: "63px" }}
                                  className="form-control"
                                  name="name"
                                  disabled={markedItems.includes(item.id) === false}
                                  placeholder="UPrice"
                                  onChange={(e) =>
                                    handleFieldChange(index, "u_price", e.target.value)
                                  }
                                  value={item.u_price}
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  id="uprice"
                                  type="text"
                                  style={{ width: "75px", minWidth: "63px" }}
                                  className="form-control"
                                  name="name"
                                  disabled="true"
                                  placeholder="T/Price"
                                  value={item.u_price * item.ctn * item.qty_ctn}
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  type="text"
                                  style={{ width: "63px", minWidth: "63px" }}
                                  className="form-control"
                                  name="name"
                                  disabled={markedItems.includes(item.id) === false}
                                  placeholder="CBM"
                                  onChange={(e) => handleFieldChange(index, "cbm", e.target.value)}
                                  value={item.cbm}
                                  id="cbm"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  type="text"
                                  style={{ width: "63px", minWidth: "63px" }}
                                  className="form-control"
                                  name="name"
                                  placeholder="T/CBM"
                                  readOnly
                                  disabled={markedItems.includes(item.id) === false}
                                  // onChange={(e) => handleTTOCBMChange(e, index)}
                                  value={item.t_cbm}
                                  id="ttocbm"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  type="text"
                                  style={{ width: "63px", minWidth: "63px" }}
                                  className="form-control"
                                  name="name"
                                  placeholder="N.W"
                                  disabled={markedItems.includes(item.id) === false}
                                  onChange={(e) => handleFieldChange(index, "n_w", e.target.value)}
                                  value={item.n_w}
                                  id="nw"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  type="text"
                                  style={{ width: "63px", minWidth: "63px" }}
                                  className="form-control"
                                  name="name"
                                  placeholder="G.W"
                                  readOnly
                                  disabled={markedItems.includes(item.id) === false}
                                  // onChange={(e) => handleGWChange(e, index)}
                                  value={item.g_w}
                                  id="gw"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  type="text"
                                  style={{ width: "100px", minWidth: "100px" }}
                                  className="form-control"
                                  name="name"
                                  placeholder="Status"
                                  disabled={markedItems.includes(item.id) === false}
                                  onChange={(e) =>
                                    handleFieldChange(index, "status", e.target.value)
                                  }
                                  value={item.status}
                                  id="status"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  type="text"
                                  style={{ width: "100px", minWidth: "100px" }}
                                  className="form-control"
                                  name="name"
                                  readOnly
                                  placeholder="O.Status"
                                  disabled={markedItems.includes(item.id) === false}
                                  onChange={(e) =>
                                    handleFieldChange(index, "order_status", e.target.value)
                                  }
                                  value={item.order_status}
                                  id="orderStatus"
                                />
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <input
                                  required
                                  type="text"
                                  style={{ width: "100px", minWidth: "100px" }}
                                  className="form-control"
                                  name="name"
                                  placeholder="Notes"
                                  disabled={markedItems.includes(item.id) === false}
                                  onChange={(e) =>
                                    handleFieldChange(index, "notes", e.target.value)
                                  }
                                  value={item.notes}
                                  id="notes"
                                />
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                        </CTableBody>
                      </ScrollSyncPane>
                    </CTable>
                  </div>
                </ScrollSync>
              ) : (
                <div>no data found</div>
              )}
            </TableContainer>
          </SuiBox>
        )}
      </Card>
      <Dialog
        open={dialogSuccess}
        onClose={() => {
          setDialogSuccess(false);
          navigate("/orders");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Order Added Successfully</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              navigate("/orders");
              setDialogSuccess(false);
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogError}
        onClose={() => {
          setDialogError(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Adding order failed</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogError(false);
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogNew}
        onClose={() => {
          setDialogNew(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          order status must be recieved for all orders
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogNew(false);
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to go back? </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigate("/containers");
            }}
            autoFocus
          >
            YES
          </Button>
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </SuiBox>
  );
}

export default AllOrders;
