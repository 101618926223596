/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import AddUser from "layouts/users/add_user";

// import currentLanguage from "examples/Navbars";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard React routes
import routes from "routes";

// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo-ct.png";
import i18n from "i18n";
import HomeScreen from "layouts/home_screen";
import AdminRoutes from "route_admin";
import CustomerRoutes from "route_customer";
import Orders from "layouts/orders";
import AllOrders from "layouts/orders/all_orders_details";
import LogOut from "examples/Navbars/DashboardNavbar/logout";
import AddOrder from "layouts/orders/add_order";
import "styles/style.css";
import SinglePost from "layouts/posts/single_post";
import AllOrdersArchive from "layouts/orders/orders_archive";

export default function App() {
  const [locale, setLocale] = useState(i18n.language);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    // console.log(currentLanguage);
    // console.log(currentLanguage);
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  // eslint-disable-next-line no-unused-vars
  const adduser = {
    key: "adduser",
    route: "/adduser",
    // icon: <Shop size="12px" />,
    component: <AddUser />,
    noCollapse: true,
  };

  const configsButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SuiBox>
  );

  return (
    <Routes>
      <Route path="/homescreen" element={<HomeScreen />} />
      <Route
        path="/single-post/:id"
        element={
          // <ThemeProvider theme={theme}>
          // < />
          <SinglePost />
          // </ThemeProvider>
        }
      />

      <Route
        path="/orders"
        element={
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Orders />
          </ThemeProvider>
        }
      />
      <Route
        path="/all_orders_archive"
        element={
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AllOrdersArchive />
          </ThemeProvider>
        }
      />
      <Route
        path="/all_orders"
        element={
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AllOrders />
          </ThemeProvider>
        }
      />
      <Route
        path="/add_order"
        element={
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AddOrder />
          </ThemeProvider>
        }
      />

      <Route
        path="*"
        element={
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={brand}
                  brandName="Trust Cargo"
                  routes={
                    sessionStorage.getItem("role_id") === "admin" ? AdminRoutes : CustomerRoutes
                  }
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                {/* <Configurator /> */}
                {/* {configsButton} */}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/homescreen" />} />
              {/* <Route path="*" element={<Navigate to="/authentication/sign-in" />} /> */}
            </Routes>
          </ThemeProvider>
        }
      />
    </Routes>
  );
}
