/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@material-ui/core";
import ReactLoading from "react-loading";
import "../../styles/style.css";

import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  // Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useOrderAdding from "hooks/orders/useOrderAdding";
// import { useTranslation } from "react-i18next";
import {
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
// import DeleteLab from "./delete_lab";

function AddOrder() {
  const {
    handleSubmit,
    validated,
    containers,
    customers,
    statusarray,
    customer,
    setCustomer,
    container,
    setContainer,
    dialogOpen,
    setDialogOpen,
    loading,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
    setDialogError,
    shopNumber,
    setShopNumber,
    orderCode,
    setOrderCode,
    phone,
    setPhone,
    deliveryDate,
    setDeliveryDate,
    totalCTN,
    totalGW,
    totalTCBM,
    totalAmount,
    rows,
    setRows,
    navigate,
    handleFieldChange,
    handleAddRow,
    handleDeleteRow,
  } = useOrderAdding();
  const { t } = useTranslation();
  return (
    <>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card style={{ padding: "2rem" }}>
            <div
              onClick={() => setDialogOpen(true)}
              style={{
                color: "#17c1e8",
                cursor: "pointer",
                marginLeft: "20px",
                marginTop: "10px",
                width: "10px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-arrow-left-square"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                />
              </svg>
            </div>
            <div style={{ width: "100%", margin: "20px" }}>
              <CForm
                className="row g-3 needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <CRow>
                  <CCol md={3}>
                    <CFormSelect
                      id="productCategory"
                      label="customer"
                      feedbackInvalid="cusotmer required"
                      value={customer}
                      onChange={(e) => setCustomer(e.target.value)}
                      required
                    >
                      <option selected="" value="">
                        {" "}
                        Choose customer ...
                      </option>

                      {customers.map((user) => (
                        <option value={user.code}>{user.name}</option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={3}>
                    <CFormInput
                      type="text"
                      placeholder="shop number"
                      feedbackInvalid="shop number is required"
                      id="categoryshopnumberAr"
                      label="shop number"
                      required
                      onChange={(e) => {
                        setShopNumber(e.target.value);
                      }}
                      value={shopNumber}
                    />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput
                      type="text"
                      placeholder="order code"
                      feedbackInvalid="order code is required"
                      id="categoryordercodeAr"
                      label="order code"
                      required
                      onChange={(e) => setOrderCode(e.target.value)}
                      value={customer + shopNumber}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <CFormInput
                      type="text"
                      placeholder="phone number"
                      feedbackInvalid="phone number is required"
                      id="categoryphonenumberAr"
                      label="phone number"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput
                      type="date"
                      placeholder="delivery date"
                      feedbackInvalid="delivery date is required"
                      id="categorydeliverydateAr"
                      label="delivery date"
                      required
                      onChange={(e) => setDeliveryDate(e.target.value)}
                      value={deliveryDate}
                    />
                  </CCol>
                  <CCol md={3}>
                    <CFormSelect
                      id="productCategory"
                      label="container"
                      feedbackInvalid="Container required"
                      value={container}
                      onChange={(e) => setContainer(e.target.value)}
                      required
                    >
                      <option selected="" value="">
                        {" "}
                        Choose container ...
                      </option>

                      {containers.map((user) => (
                        <option value={user.id}>{user.name}</option>
                      ))}
                    </CFormSelect>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total items"
                      feedbackInvalid="total items is required"
                      id="categorytotalitemsAr"
                      label="total items"
                      readOnly
                      value={rows.length}
                    />
                  </CCol>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total amount"
                      feedbackInvalid="total amount is required"
                      id="categorytotalamountAr"
                      label="total amount"
                      readOnly
                      value={totalAmount}
                    />
                  </CCol>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total CTN"
                      feedbackInvalid="total CTN is required"
                      id="categorytotalCTNAr"
                      label="total CTN"
                      readOnly
                      value={totalCTN}
                    />
                  </CCol>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total CBM"
                      feedbackInvalid="total CBM is required"
                      id="categorytotalCBMAr"
                      label="total CBM"
                      readOnly
                      value={totalTCBM}
                    />
                  </CCol>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total GW"
                      feedbackInvalid="total GW is required"
                      id="categorytotalGWAr"
                      label="total GW"
                      readOnly
                      value={totalGW}
                    />
                  </CCol>
                </CRow>
                <SuiBox
                  style={{
                    backgroundColor: "#e9ecef",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <TableContainer className="mt-2">
                    <CTable bordered>
                      <CTableHead color="light">
                        <CTableRow style={{ fontSize: "18px", position: "sticky" }}>
                          <CTableHeaderCell className="text-center">S.Code</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Item No.</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Image</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Desc En.</CTableHeaderCell>
                          <CTableHeaderCell className="text-center"> CTN</CTableHeaderCell>
                          <CTableHeaderCell className="text-center"> QTY/CTN</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">T/QTY</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">UPrice</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Amount</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">CBM</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">T/CBM</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">N.W</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">G.W</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Notes</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Operations</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {rows.map((row, index) => (
                          <CTableRow v-for="item in tableItems" key={index}>
                            <CTableDataCell className="text-center">
                              <input
                                id="item"
                                required
                                onChange={(e) =>
                                  handleFieldChange(index, "our_item", e.target.value)
                                }
                                value={customer + shopNumber + -+(index + 1)}
                                type="text"
                                style={{ width: "100px", minWidth: "100px" }}
                                className="form-control"
                                name="name"
                                placeholder="Our Item"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <input
                                id="item"
                                required
                                onChange={(e) =>
                                  handleFieldChange(index, "item_number", e.target.value)
                                }
                                value={row.item_number}
                                type="text"
                                style={{ width: "100px", minWidth: "100px" }}
                                className="form-control"
                                name="name"
                                placeholder="Item Number"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              {" "}
                              <div style={{ display: "flex" }}>
                                <input
                                  id="photo"
                                  required
                                  type="file"
                                  style={{ width: "50px", minWidth: "50px" }}
                                  className="form-control"
                                  name="name"
                                  // placeholder="Photo"
                                  onChange={(e) =>
                                    handleFieldChange(index, "photo", e.target.files[0])
                                  }
                                  // value={photo[index]}
                                />
                                <img
                                  src={row.photo ? URL.createObjectURL(row.photo) : ""}
                                  width={70}
                                />
                              </div>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <input
                                required
                                id="desc2"
                                type="text"
                                style={{ width: "150px", minWidth: "150px" }}
                                className="form-control"
                                name="name"
                                placeholder="English"
                                onChange={(e) =>
                                  handleFieldChange(index, "description2", e.target.value)
                                }
                                value={row.description2}
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <input
                                required
                                type="number"
                                style={{ width: "60px", minWidth: "60px" }}
                                className="form-control"
                                name="name"
                                placeholder="CTN"
                                onChange={(e) => handleFieldChange(index, "ctn", e.target.value)}
                                value={row.ctn}
                                id="ctn"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <input
                                required
                                type="number"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="QTY/CTN"
                                onChange={(e) =>
                                  handleFieldChange(index, "qty_ctn", e.target.value)
                                }
                                value={row.qty_ctn}
                                id="qtytoctn"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              {" "}
                              <input
                                required
                                type="number"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="T/QTY"
                                readOnly
                                // onChange={(e) => handleTTOQTYChange(e, index)}
                                value={
                                  row.ctn === "0" || row.ctn === ""
                                    ? row.qty_ctn
                                    : row.ctn * row.qty_ctn
                                }
                                id="ttoqty"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <input
                                required
                                type="number"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="U/Price"
                                onChange={(e) =>
                                  handleFieldChange(index, "u_price", e.target.value)
                                }
                                value={row.u_price}
                                id="utoprice"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <input
                                required
                                type="number"
                                style={{ width: "80px", minWidth: "80px" }}
                                className="form-control"
                                name="name"
                                readOnly
                                placeholder="Amount"
                                // onChange={(e) => handleFieldChange(index, "amount", e.target.value)}
                                value={
                                  row.ctn === "0" || row.ctn === ""
                                    ? row.qty_ctn * row.u_price
                                    : row.ctn * row.qty_ctn * row.u_price
                                }
                                // id="amount"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <input
                                required
                                type="number"
                                style={{ width: "60px", minWidth: "60px" }}
                                className="form-control"
                                name="name"
                                placeholder="CBM"
                                onChange={(e) => handleFieldChange(index, "cbm", e.target.value)}
                                value={row.cbm}
                                id="cbm"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              {" "}
                              <input
                                required
                                type="number"
                                style={{ width: "60px", minWidth: "60px" }}
                                className="form-control"
                                name="name"
                                placeholder="T/CBM"
                                readOnly
                                // onChange={(e) => handleTTOCBMChange(e, index)}
                                value={row.t_cbm}
                                id="ttocbm"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <input
                                required
                                type="number"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="N.W"
                                onChange={(e) => handleFieldChange(index, "n_w", e.target.value)}
                                value={row.n_w}
                                id="nw"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              {" "}
                              <input
                                required
                                type="number"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="G.W"
                                readOnly
                                // onChange={(e) => handleGWChange(e, index)}
                                value={row.g_w}
                                id="gw"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <select
                                id="customer_id"
                                required
                                onChange={(e) => handleFieldChange(index, "status", e.target.value)}
                                className="form-control"
                                style={{ height: "40px" }}
                                value={row.status}
                              >
                                <option value="" selected="">
                                  Select status
                                </option>
                                {statusarray.map((user) => (
                                  <option value={user.name}>{user.name}</option>
                                ))}
                              </select>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <input
                                required
                                type="text"
                                style={{ width: "150px", minWidth: "150px" }}
                                className="form-control"
                                name="name"
                                placeholder="Notes"
                                onChange={(e) => handleFieldChange(index, "notes", e.target.value)}
                                value={row.notes}
                                id="notes"
                              />
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <Button
                                variant="contained"
                                style={{
                                  color: "white",
                                  width: "120px",
                                  fontSize: "10px",
                                  marginLeft: "10px",
                                }}
                                disabled={rows.length === 1}
                                onClick={() => handleDeleteRow(index)}
                              >
                                Remove Item
                              </Button>
                            </CTableDataCell>
                          </CTableRow>
                        ))}
                      </CTableBody>
                    </CTable>
                  </TableContainer>
                  <Button
                    variant="contained"
                    style={{
                      color: "white",
                      width: "100px",
                      minWidth: "100px",
                      fontSize: "10px",
                      margin: "1rem",
                    }}
                    onClick={() => handleAddRow()}
                  >
                    Add Item
                  </Button>
                </SuiBox>
                {loading ? (
                  <ReactLoading color="blue" height={50} width={50} type="spokes" />
                ) : (
                  <Button
                    variant="contained"
                    style={{ margin: "20px", color: "white", width: "15%" }}
                    type="submit"
                  >
                    <span style={{ fontWeight: "bolder" }}>Add Order</span>
                  </Button>
                )}
              </CForm>
              <Dialog
                open={dialogSuccess}
                onClose={() => {
                  setDialogSuccess(false);
                  navigate("/orders");
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Order Added Successfully</DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      navigate("/orders");
                    }}
                    autoFocus
                  >
                    {t("ok")}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={dialogError}
                onClose={() => {
                  setDialogError(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Adding order failed</DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDialogError(false);
                    }}
                    autoFocus
                  >
                    {t("ok")}
                  </Button>
                </DialogActions>
              </Dialog>
              {/* <div className="row">
                <div className="col-md-2" style={{ marginTop: "20px" }}>
                  <div className="form-group">
                    <AlertDialog
                      ItemNumber1={itemnumber}
                      ItemArray={itemarray}
                      photo={photo}
                      desc1={description1}
                      desc2={description2}
                      category={category}
                      ctn={ctn}
                      qtytoctn={qtytoctn}
                      totoqty={ttoqty}
                      utoprice={utoprice}
                      amount={amount}
                      cbm={cbm}
                      ttocbm={ttocbm}
                      nw={nw}
                      gw={gw}
                      status={status}
                      notes={notes}
                      shop_number1={shopnumber1}
                      shop_number2={shopnumber2}
                      customer_id={customer}
                      container_id={container}
                      phone_number={phone}
                      // order_number={`${shopnumber1}-${customer}`}
                      order_number={customer + shopnumber2}
                      delivery_date={deliverydate}
                      total_amount1={total_amount.toString()}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total Items
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total Items"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={itemarray.length}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total Amount
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total Amount"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_amount}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total CTN
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total CTN"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={Number(total_ctn)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total CBM
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total CBM"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_cbm}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total GW
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total GW"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_gw}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to go back? </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
Let Google help apps determine location. This means sending anonymous location data to
Google, even when no apps are running.
</DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate("/containers");
            }}
            autoFocus
          >
            YES
          </Button>
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </>
  );
}

export default AddOrder;
