/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-named-as-default
import Dashboard from "layouts/dashboard";
// import XrayPictures from "layouts/xray_pictures";
// import Users from "layouts/users";
// import Customers from "layouts/customers";
// import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
// import CustomerSupport from "examples/Icons/CustomerSupport";
// import CreditCard from "examples/Icons/CreditCard";
import Orders from "layouts/orders";
// import Drugs from "layouts/drugs";
import Transactions from "layouts/transactions";
import BackUpData from "layouts/back_up";
// import Expenses from "layouts/expenses";
import TestUsers from "layouts/users/test";
import Totals from "layouts/totals/totals";
import Customers from "layouts/customers";
import Containers from "layouts/containers";
import AllOrders from "layouts/orders/all_orders_details";
import Posts from "layouts/posts";
import Messages from "layouts/messages";
import Status from "layouts/status";
import CustomerOrders from "layouts/customer_orders";
import LogOut from "examples/Navbars/DashboardNavbar/logout";
import AllOrdersArchive from "layouts/orders/orders_archive";

// import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
// const { t } = useTranslation();
const AdminRoutes = [
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <Office size="12px" />,
    component: <TestUsers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    route: "/customers",
    icon: <Office size="12px" />,
    component: <Customers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Containers",
    key: "containers",
    route: "/containers",
    icon: <Office size="12px" />,
    component: <Containers />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    route: "/orders",
    icon: <Office size="12px" />,
    component: <Orders />,
    noCollapse: true,
    noSidebar: true,
  },

  {
    type: "collapse",
    name: "All Orders",
    key: "all_orders",
    route: "/all_orders",
    icon: <Office size="12px" />,
    component: <AllOrders />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "All Orders Archive",
    key: "all_orders_archive",
    route: "/all_orders_archive",
    icon: <Office size="12px" />,
    component: <AllOrdersArchive />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Posts",
    key: "posts",
    route: "/posts",
    icon: <Office size="12px" />,
    component: <Posts />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    route: "/messages",
    icon: <Office size="12px" />,
    component: <Messages />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Status",
    key: "status",
    route: "/status",
    icon: <Office size="12px" />,
    component: <Status />,
    noCollapse: true,
  },
  {
    type: "logout",
    name: "",
    key: "",
    icon: <Office size="12px" />,
    component: <LogOut />,
    noCollapse: true,
  },
];

export default AdminRoutes;
