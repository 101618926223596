/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
/* eslint-disable no-var */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEffect, useState } from "react";
import axios from "axios";
import useCustomers from "hooks/customers/useCustomers";
import useContainers from "hooks/containers/useContainers";
import useStatus from "hooks/status/useStatus";

const useOrderEditing = (orderId) => {
  const [rows, setRows] = useState([]);
  const { customers } = useCustomers();
  const { containers } = useContainers();
  const { status: statusarray } = useStatus();
  const [customer, setCustomer] = useState("");
  const [container, setContainer] = useState("");
  const [orderCode, setOrderCode] = useState("");
  const [shopNumber, setShopNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [totalCTN, setTotalCTN] = useState(0);
  const [totalGW, setTotalGW] = useState(0);
  const [totalTCBM, setTotalTCBM] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const getSpeceficOrder = async () => {
    setLoading(true);
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/specefic_order/${orderId}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setRows(data["order"]["products"]);
    setCustomer(data["order"]["customer_id"]);
    setContainer(data["order"]["container_id"]);
    setPhone(data["order"]["phone_number"]);
    setOrderCode(data["order"]["order_code"]);
    setDeliveryDate(data["order"]["delivery_date"]);
    setShopNumber(data["order"]["order_code"].substring(3));
    setLoading(false);
  };

  useEffect(() => {
    getSpeceficOrder();
  }, []);

  const handleFieldChange = (index, field, value) => {
    const updatedRow = [...rows];
    updatedRow[index][field] = value;
    switch (field) {
      case "ctn":
        if (updatedRow[index].ctn === "0" || updatedRow[index].ctn === "") {
          console.log(updatedRow[index].qty_ctn * updatedRow[index].u_price);
          console.log("ctn = ", updatedRow[index].ctn);
          updatedRow[index] = {
            ...updatedRow[index],
            t_qty: updatedRow[index].qty_ctn,
            amount: updatedRow[index].qty_ctn * updatedRow[index].u_price,
            t_cbm: updatedRow[index].ctn * updatedRow[index].cbm,
            g_w: updatedRow[index].n_w * updatedRow[index].ctn,
          };
        } else {
          updatedRow[index] = {
            ...updatedRow[index],
            t_qty: updatedRow[index].ctn * updatedRow[index].qty_ctn,
            amount: updatedRow[index].ctn * updatedRow[index].qty_ctn * updatedRow[index].u_price,
            t_cbm: updatedRow[index].ctn * updatedRow[index].cbm,
            g_w: updatedRow[index].n_w * updatedRow[index].ctn,
          };
        }
        break;
      case "qty_ctn":
        if (updatedRow[index].ctn === "0" || updatedRow[index].ctn === "") {
          updatedRow[index] = {
            ...updatedRow[index],
            t_qty: updatedRow[index].qty_ctn,
            amount: updatedRow[index].qty_ctn * updatedRow[index].u_price,
          };
        } else {
          updatedRow[index] = {
            ...updatedRow[index],
            t_qty: updatedRow[index].ctn * updatedRow[index].qty_ctn,
            amount: updatedRow[index].ctn * updatedRow[index].qty_ctn * updatedRow[index].u_price,
          };
        }
        break;
      case "u_price":
        updatedRow[index] = {
          ...updatedRow[index],
          amount: updatedRow[index].t_qty * updatedRow[index].u_price,
        };
        break;
      case "cbm":
        updatedRow[index] = {
          ...updatedRow[index],
          t_cbm: updatedRow[index].ctn * updatedRow[index].cbm,
        };
        break;
      case "n_w":
        updatedRow[index] = {
          ...updatedRow[index],
          g_w: updatedRow[index].n_w * updatedRow[index].ctn,
        };
        break;
      default:
        break;
    }
    setRows(updatedRow);
    console.log(updatedRow);
  };

  const calculateTotals = () => {
    console.log("entered calculation");
    const toCTN = rows.reduce((total, e) => {
      return total + Number(e.ctn);
    }, 0);
    const toGW = rows.reduce((total, e) => {
      return total + Number(e.g_w);
    }, 0);
    const toTCBM = rows.reduce((total, e) => {
      return total + Number(e.t_cbm);
    }, 0);
    const toAmount = rows.reduce((total, e) => {
      return total + Number(e.amount);
    }, 0);
    console.log(rows);
    console.log(toCTN, toTCBM, toAmount, toGW);

    setTotalCTN(toCTN.toFixed(2));
    setTotalTCBM(toTCBM.toFixed(2));
    setTotalAmount(toAmount.toFixed(2));
    setTotalGW(toGW.toFixed(2));
  };

  useEffect(() => {
    calculateTotals();
  }, [rows]);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: "0",
        our_item: rows[0].order_code,
        item_number: "",
        photo: "",
        description2: "",
        ctn: "",
        qty_ctn: "",
        t_qty: "",
        u_price: "",
        amount: "",
        cbm: "",
        t_cbm: "",
        n_w: "",
        g_w: "",
        status: "",
        order_status: "",
        notes: "",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const newRows = rows.filter((row, rowIndex) => rowIndex !== index);
    setRows(newRows);
  };

  function editOrder() {
    const data = {
      order_id: Number(orderId),
      customer_id: customer,
      phone_number: phone,
      delivery_date: deliveryDate,
      order_code:  customer + shopNumber,
      container_id: container,
      items: rows.map((row, index) => ({
        item_number:`${customer + shopNumber}-${index+1}`,
        customer_id: customer,
        container_id: container,
        our_item:`${customer + shopNumber}-${index+1}`,
        order_code: customer + shopNumber,
        photo: row.photo,
        description1: row.description2,
        description2: row.description2,
        material: "material",
        category: "Category X",
        ctn: Number(row.ctn),
        qty_ctn: Number(row.qty_ctn),
        t_qty: Number(row.t_qty),
        u_price: Number(row.u_price),
        t_cbm: Number(row.t_cbm),
        amount: Number(row.amount),
        cbm: Number(row.cbm),
        status: "new",
        notes: row.notes,
        n_w: Number(row.n_w),
        g_w: Number(row.g_w),
      })),
    };

    const headers = { "Content-Type": "application/json" };
    const url = "https://trust-cargo.co/anas_backend/api/edit_order";

    axios
      .post(url, data, headers)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const addRow = () => {};

  return {
    handleFieldChange,
    rows,
    loading,
    dialogError,
    setDialogError,
    dialogSuccess,
    setDialogSuccess,
    editOrder,
    handleDeleteRow,
    handleAddRow,
    totalCTN,
    totalGW,
    totalTCBM,
    totalAmount,
    customers,
    containers,
    statusarray,
    customer,
    setCustomer,
    container,
    setContainer,
    orderCode,
    setOrderCode,
    phone,
    setPhone,
    deliveryDate,
    setDeliveryDate,
    shopNumber,
    setShopNumber
  };
};

export default useOrderEditing;
